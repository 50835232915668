import { DatePicker, Tooltip } from "antd";
import "antd/dist/antd.css";
import "cdbreact";
import { Field, Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Label, Row, Spinner } from "reactstrap";
import {
  THEME_TYPE,
  geoFenceEventValidationSchema,
  tamperEventsDownloadColumns,
} from "../../../../constants";
import { getDeviceListForProj, getProjectListDD } from "../../../../redux/reducers/dropdowns/dropdown.actions";
import {
  endLoading,
  startLoading,
} from "../../../../redux/reducers/general/general.actions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../redux/store/store";
import FullPageLoaderModal from "../../../Common/FullPageLoader/FullPageLoaderModal";
import TamperEventsTab from "./tab";
import { LayoutContext } from "../../../../context/LayoutContext";
import { convertDateToTimestamp, convertTimestampToDate } from "../../../../services/common.functions.services";
import { getTamperEvents } from "../../../../redux/reducers/DataReducer/data.actions";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const { RangePicker } = DatePicker;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function TamperEvents(props: Props) {
  const { selectedTheme, selectedProject } = useContext(LayoutContext);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsPerPage, setRecordsPerPage]: $TSFixMe = useState(10);
  const [selectedPage, setSelectedPage]: $TSFixMe = useState(1);
  const [isAsc, setIsAsc]: $TSFixMe = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [sortColumn, setSortColumn]: $TSFixMe = useState("");
  const [selectedFilter, setSelectedFilter]: $TSFixMe = useState({
    project: null,
    vehicleRegNo: null,
    dateTimeYear: null,
  });

  const cardColorType = selectedTheme === THEME_TYPE.GREY_SCALE || selectedTheme === THEME_TYPE.BLUE_WHITE_GREY ? 'cardcolor-light' : 'cardcolor';
  const formikRef: $TSFixMe = useRef();

  const preprocessCsvData = (csvData: any[]) => {
    return csvData.map(data => ({
      ...data,
      timestamp: convertTimestampToDate(props.user.timeZone.zoneId, data.timestamp, null, props.user.timeFormat)
    }));
  };

  const handleFilter = async (values: $TSFixMe, type?: $TSFixMe) => {
    if (type !== "noFormSubmit") {
      setIsLoading(true);
      setSelectedPage(1);
    }
    try {
      let data = {
        asc: isAsc,
        column: sortColumn,
        projectId: values?.project?.id,
        deviceId: values?.vehicleRegNo?.otherValue1,
        page: {
          no: type === "noFormSubmit" ? selectedPage : 1,
          size: recordsPerPage
        },
        startTimestamp: values.dateTimeYear && values.dateTimeYear[0] ? convertDateToTimestamp(values?.dateTimeYear[0], props?.user?.timeZone?.zoneId) : null,
        endTimestamp: values.dateTimeYear && values.dateTimeYear[1] ? convertDateToTimestamp(values?.dateTimeYear[1], props?.user?.timeZone?.zoneId) : null,
      };
      await props.getTamperEvents(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsPageLoading(false);
    }
  };

  const apiCall = async () => {
    props.startLoading();
    if (props.dropdowns.projectListDD && props.dropdowns.projectListDD?.result?.length === 0) {
      props.getProjectListDD();
    }
    props.endLoading();
  };

  useEffect(() => {
    if (selectedFilter.project) {
      props.getDeviceListDD(selectedFilter.project.id);
    }
    return () => { }
  }, [selectedFilter.project]);

  useEffect(() => {
    if (selectedFilter.project && !isLoading) {
      handleFilter(selectedFilter, "noFormSubmit");
    }
    return () => { }
  }, [recordsPerPage, selectedPage, isAsc, sortColumn]);

  useEffect(() => {
    if (props.user.userId !== 0) {
      apiCall();
    }
  }, [props.user.userId]);


  useEffect(() => {
    if (selectedProject && Object.keys(selectedProject).length > 0) {
      setSelectedPage(1);
      setSelectedFilter((prev: $TSFixMe) => {
        return { ...prev, project: selectedProject, vehicleRegNo: null }
      });
      formikRef.current.setFieldValue("vehicleRegNo", {});
      let tempData = selectedFilter;
      tempData['project'] = selectedProject
      tempData['vehicleRegNo'] = null
      handleFilter(tempData, "noFormSubmit");
    }
    return () => { }
  }, [selectedProject]);

  return (
    <div className="header bg-gradient-info pb-1 pt-0 pt-md-8">
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--7" fluid>
        <Row className="mt-0">
          <Col>
            <Card className={`card-stats md-4 mb-xl-0 ${cardColorType} allPage`}>
              <Formik
                validationSchema={geoFenceEventValidationSchema}
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={{
                  vehicleRegNo: {},
                  project: selectedProject ?? {},
                  dateTimeYear: "",
                }}
                onSubmit={handleFilter}
              >
                {({ handleBlur, setFieldValue, errors, touched, setTouched, handleSubmit, isSubmitting }) => (
                  <>
                    <div className="mb-4">
                      <Row className="mb-4">
                        <Col sm={12} md={6} className="tripsname">
                          <div className="tabpage">
                            <>Tamper Events</>
                          </div>
                        </Col>
                        <Col sm={12} md={6} className="pageEnd">
                          {props.tamperEvents &&
                            props.tamperEvents.result &&
                            props.tamperEvents.result.data.length ? (
                            <CSVLink
                              filename={"tamper-events-file.csv"}
                              data={preprocessCsvData(props?.tamperEvents?.result?.data)}
                              headers={tamperEventsDownloadColumns}
                            >
                              <Button className={`${selectedTheme}`}>
                                Download
                              </Button>
                            </CSVLink>
                          ) : null}
                        </Col>
                      </Row>

                      <div className={`${selectedTheme}`}>
                        <div className="align-items-baseline d-flex flex-wrap">
                          <div className="w-25 pr-2">
                            <Label
                              className="modlabel modinpulabel mb-1 f-13"
                            >
                              Project
                            </Label>
                            <Select
                              className={`${touched.project && errors.project ? "requireSelect" : ""}`}
                              placeholder="Project"
                              classNamePrefix={"filter-select-style"}
                              options={props.dropdowns.projectListDD.result ?? []}
                              value={
                                selectedFilter.project
                                  ? props.dropdowns.projectListDD?.result?.find(
                                    (option: any) => option.id === selectedFilter.project.id
                                  )
                                  : null
                              }
                              getOptionLabel={(option: $TSFixMe) => option.name}
                              getOptionValue={(option: $TSFixMe) => option.id.toString()}
                              onChange={(option: $TSFixMe) => {
                                setTouched({ ...touched, project: false });
                                setSelectedFilter((prev: $TSFixMe) => {
                                  return { ...prev, project: option, vehicleRegNo: null }
                                });
                                setFieldValue("project", option);
                                setFieldValue("vehicleRegNo", {});
                              }}
                              maxMenuHeight={180}
                              onBlur={handleBlur("project")}
                            />
                            {touched.project && errors.project && (
                              <div className="validate">
                                {errors.project ? "Required" : errors.project}
                              </div>
                            )}
                          </div>
                          <div className="w-25 px-2">
                            <Label
                              className="modlabel modinpulabel mb-1 f-13"
                            >
                              Vehicle Reg#
                            </Label>
                            <Select
                              className={`${touched.vehicleRegNo && errors.vehicleRegNo ? "requireSelect" : ""}`}
                              placeholder="Vehicle Reg#"
                              classNamePrefix={"filter-select-style"}
                              options={props?.dropdowns?.device_proj?.result || []}
                              getOptionLabel={(option: $TSFixMe) =>
                                option.name ?? option.otherValue1
                              }
                              getOptionValue={(option: $TSFixMe) => option.id.toString()}
                              onChange={(option: $TSFixMe) => {
                                setSelectedFilter((prev: $TSFixMe) => {
                                  return { ...prev, vehicleRegNo: option, }
                                });
                                setFieldValue("vehicleRegNo", option)
                              }}
                              maxMenuHeight={180}
                              value={
                                selectedFilter.vehicleRegNo
                                  ? props?.dropdowns?.deviceListDD?.result?.find(
                                    (option: any) => option.id === selectedFilter.vehicleRegNo
                                  )
                                  : null
                              }
                              onBlur={handleBlur("vehicleRegNo")}
                            />
                            <div className="validate">&nbsp;</div>
                          </div>
                          <div className="w-40 pl-2">
                            <Label
                              className="modlabel modinpulabel mb-1 f-13 d-block"
                            >
                              Select Date
                            </Label>
                            <Field
                              component={RangePicker}
                              name="dateTimeYear"
                              dropdownClassName={(selectedTheme === "claret_blue" || selectedTheme === "black_pink_purple") ? "custom-date-range-picker" : selectedTheme}
                              className={`rangeHei ${(errors.dateTimeYear && touched.dateTimeYear ? "is-invalid " : "")}`}
                              onChange={(date: $TSFixMe, dateString: $TSFixMe) => {
                                if (dateString[0] !== "") {
                                  setSelectedFilter((prev: $TSFixMe) => {
                                    return { ...prev, dateTimeYear: dateString }
                                  });
                                  setFieldValue("dateTimeYear", dateString);
                                } else {
                                  setSelectedFilter((prev: $TSFixMe) => {
                                    return { ...prev, dateTimeYear: null }
                                  });
                                  setFieldValue("dateTimeYear", null);
                                }
                              }}
                              onBlur={handleBlur("dateTimeYear")}
                              format="MM-DD-YYYY HH:mm:ss"
                              showTime
                              inputProps={{
                                onBlur: handleBlur("dateTimeYear"),
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base: $TSFixMe) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                            <div className="validate">&nbsp;</div>
                          </div>
                          <div className="w-10 pl-2 mt-auto">
                            <div onClick={() => handleSubmit()} className="header-icons-btn ml-auto">
                              <Tooltip title={"Filter"}
                                align={{
                                  points: ['bc', 'tc'], // Align tooltip top-center to button bottom-center
                                  offset: [0, 5], // Adjust the offset (x, y)
                                  targetOffset: [0, 0], // Adjust the target offset
                                }}
                                overlayInnerStyle={{ fontSize: '12px', padding: '3px 8px', minHeight: '22px' }}>

                                {isLoading ? <div className="header-icons d-flex align-items-center justify-content-center"><Spinner color="light"
                                  size="sm" /></div> : <FilterAltIcon className="header-icons" />}
                              </Tooltip>
                            </div>
                            <div className="validate">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CardBody className="tabAll">
                      <TamperEventsTab
                        setRecordsPerPage={setRecordsPerPage}
                        recordsPerPage={recordsPerPage}
                        selectedPage={selectedPage}
                        setSelectedPage={setSelectedPage}
                        setSortColumn={setSortColumn}
                        setIsAsc={setIsAsc}
                        isPageLoading={isPageLoading}
                        setIsPageLoading={setIsPageLoading}
                      />
                    </CardBody>
                  </>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  tamperEvents: state.dataStore.tamperEvents,
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getProjectListDD: () => dispatch(getProjectListDD()),
  getDeviceListDD: (id: number) => dispatch(getDeviceListForProj(id)),
  getTamperEvents: (data: $TSFixMe) => dispatch(getTamperEvents(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TamperEvents);
