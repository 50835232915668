import axios from "axios";
import { API, BASEURL } from "./api.constants";
import { get, patch, post, postformData, put } from "../services/axios.services";
import { AddCommonConfigurationTemplateType } from "../../global.types";


// Profile related apis
const forgotPassword = (data: { email: string }) => {
  return axios.post(BASEURL + API.FORGOT_PASSWORD, null, {
    params: data
  });
};

const resetPassword = (data: { param: string, password: string }) => {
  return axios.post(BASEURL + API.RESET_PASSWORD, data,);
};

const uploadProfilePicture = (data: { param: $TSFixMe, formData: $TSFixMe }) => {
  return postformData(BASEURL + API.USER_PROFILE, data.formData, {
    params: data.param
  });
};

const updatePassword = (data: { currentPassword: string, newPassword: string }) => {
  return put(BASEURL + API.UPDATE_PASSWORD, data);
};

// to login from authenticated url
const loginByToken = (params: $TSFixMe) => {
  return post(BASEURL + API.LOGIN_BY_TOKEN, "", { params });
}

const getRefreshToken = (params: $TSFixMe) => {
  return post(BASEURL + API.REFRESH_TOKEN_API, null, {
    params
  });
};


// Notification related Apis
const getNotifications = (data: $TSFixMe) => {
  return post(BASEURL + API.NOTIFICATION_LIST, data);
}

const removeNotifications = (id: $TSFixMe) => {
  return get(BASEURL + API.NOTIFICATION_CLEAR + "/" + id);
}
const removeAllNotifications = () => {
  return get(BASEURL + API.NOTIFICATION_CLEAR);
}

const notificationRead = (id: $TSFixMe) => {
  return get(BASEURL + API.NOTIFICATION_READ + "/" + id);
};

const notificationReadAll = () => {
  return get(BASEURL + API.NOTIFICATION_READ_ALL);
};


// Common configurations Apis 
const assignConfiguration = (data: $TSFixMe) => {
  return post(API.COMMON_CONFIGURATION_ASSIGN_DEVICE, data,);
};

const addCommonConfiguration = (data: AddCommonConfigurationTemplateType) => {
  return post(API.COMMON_CONFIGURATION_ADD, data,);
};

const editCommonConfiguration = (data: $TSFixMe) => {
  return put(API.COMMON_CONFIGURATION_UPDATE + "/" + data.id, data);
}

const updateCommonConfigurationTemplateImei = (data: $TSFixMe) => {
  return put(`${API.COMMON_CONFIGURATION_IMEI}/${data.id}`, data.data);
}

const verifyDevice = (data: $TSFixMe) => {
  return post(BASEURL + API.COMMON_CONFIGURATION_VERIFY_DEVICE, data);
}

const restoreFactorySettings = () => {
  return get(BASEURL + API.FACTORY_SETTINGS);
};

const getWakeUpCondition = () => {
  return get(BASEURL + API.WAKE_UP_CONDITION);
};

const getWakeUpConditionTime = () => {
  return get(BASEURL + API.WAKE_UP_CONDITION_TIME);
};

const getLowEventVideoRequest = (data: $TSFixMe) => {
  return post(BASEURL + API.LOW_EVENT_VIDEO_REQUEST, data);
}

const updateCommonConfiguration = (data: $TSFixMe) => {
  return put(`${BASEURL + API.COMMON_CONFIGURATION}/${data.moduleURL}/${data.id}`, data.data);
}

const addCommonConfigurationSection = (data: $TSFixMe) => {
  return post(`${BASEURL + API.COMMON_CONFIGURATION}/${data.moduleURL}/${data.id}`, data.data);
}

const updateCommonConfigurationName = (data: $TSFixMe) => {
  return put(`${BASEURL + API.COMMON_CONFIGURATION}/${data.id}`, data.data);
}

const addCommonConfigurationModule = (data: $TSFixMe) => {
  return post(`${BASEURL + API.COMMON_CONFIGURATION}/${data.moduleURL}`, data.data);
}
const addCommonConfigurationName = (data: $TSFixMe) => {
  return post(`${BASEURL + API.COMMON_CONFIGURATION}`, data.data);
}


// events video screen related apis 
const getEventVideoInformation = (data: $TSFixMe, type: string) => {
  return post(BASEURL + API[`${type}`], data)
}

const getEventVideoGyroInformation = (data: $TSFixMe, type: string) => {
  return post(BASEURL + API[`${type}_GYRO`], data)
}

const getEventVideoSpeedLocation = (data: $TSFixMe, type: string) => {
  return post(BASEURL + API[`${type}_SPEED_LOCATION`], data)
}

const getAdminNote = (data: $TSFixMe, type: string, id: $TSFixMe) => {
  return post(BASEURL + API[`${type}_ADMIN_NOTE`] + `/${id}`, data)
};

const addAdminNote = (data: $TSFixMe, type: string) => {
  return post(BASEURL + API[`${type}_ADMIN_NOTE`], data)
};


// dashboard related apis
const getGlobalSearchData = (param: $TSFixMe) => {
  return get(BASEURL + API.GLOBAL_SEARCH, {
    params: param
  });
};

const getOnlineDevice = (data: $TSFixMe) => {
  return post(BASEURL + API.ONLINE_DEVICE, data);
}

const getTotalDevice = (data: $TSFixMe) => {
  return post(BASEURL + API.TOTAL_DEVICE, data);
}

const getTotalMilesProtected = (data: $TSFixMe) => {
  return post(BASEURL + API.TOTAL_MILES_PROTECTED, data);
}

const getProjectSplit = (data: $TSFixMe) => {
  return post(BASEURL + API.PROJECT_SPLIT, data);
}

const getTopDriver = (data: $TSFixMe) => {
  return post(BASEURL + API.TOP_DRIVER, data);
}

const getVehicleEventExceptionSummary = (data: $TSFixMe, type: string) => {
  return post(BASEURL + API.VEHICLE_EVENT_EXCEPTION_SUMMARY + `/${type}`, data);
}

const getLowEventVehicleTrip = (data: $TSFixMe) => {
  return post(BASEURL + API.VEHICLE_TRIP_LOW_EVENT, data);
}

const getHighEventVehicleTrip = (data: $TSFixMe) => {
  return post(BASEURL + API.VEHICLE_TRIP_HIGH_EVENT, data);
}


// request video model 
const requested_video = (data: $TSFixMe) => {
  return put(BASEURL + API.REQUESTED_VIDEO, data)
};


// Maps related Apis
const getTripEventInfo = (data: $TSFixMe, type: string) => {
  return post(BASEURL + API[`${type}`], data)
}

const getMonitorMarkerData = (id: $TSFixMe) => {
  return get(BASEURL + API.MONITOR_MARKER_DATA + "/" + id);
};


// Device status action buttons apis
const resetDevice = (data: $TSFixMe) => {
  return post(BASEURL + API.DEVICE_RESET, data)
}

const videoRequest = (data: $TSFixMe) => {
  return post(BASEURL + API.DEVICE_VIDEO_REQUEST, data)
}


//  deriver score card related apis
const getHarshEvents = (id: number) => {
  return get(BASEURL + API.HARSH_EVENTS + `/${id}`);
};

const getHoursPerDay = (id: number) => {
  return get(BASEURL + API.HOURS_PER_DAY + `/${id}`);
};

const getDriverStatisticsJourney = (id: number) => {
  return get(BASEURL + API.DRIVER_STATISTICS_JOURNEY + `/${id}`);
};


// device related apis
const healthCheck = (id: number, data: $TSFixMe) => {
  return post(BASEURL + API.HEALTH_CHECK + `/${id}`, data);
}

const getDeviceLog = (id: number, data: $TSFixMe) => {
  return post(BASEURL + API.GET_DEVICE_LOG + `/${id}`, data);
}


// trips and geo fence related apis
const getGeoFenceEvents = (data: $TSFixMe) => {
  return post(BASEURL + API.GEO_FENCE_EVENT, data);
}

const tripRecalculate = (data: $TSFixMe, id: number) => {
  return patch(BASEURL + API.TRIP_RECALCULATE + '/' + id, data);
};

const getGpsInfo = (data: $TSFixMe) => {
  return post(BASEURL + API.GPS_INFORMATION, data);
}


// upload the logo for the project
const uploadPicture = (data: $TSFixMe) => {
  return postformData(BASEURL + API.UPLOAD_PROJECT_LOGO, data);
};

const getTripVideoRequest = (data: $TSFixMe) => {
  return post(BASEURL + API.TRIP_VIDEO_REQUEST, data);
}

// get the revers geocoding
const getLocation = (latitude: number, longitude: number) => {
  return axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`);
};

const getManageRoleById = (id: $TSFixMe) => {
  return get(BASEURL + API.MANAGEMENT_ROLE + "/" + id);
};

export {
  getMonitorMarkerData,
  getManageRoleById,
  getTripVideoRequest,
  getHarshEvents,
  getGpsInfo,
  getGeoFenceEvents,
  getHoursPerDay,
  resetDevice,
  videoRequest,
  getDriverStatisticsJourney,
  getDeviceLog,
  healthCheck,
  getAdminNote,
  getProjectSplit,
  addAdminNote,
  getTripEventInfo,
  getOnlineDevice,
  getTotalDevice,
  getTopDriver,
  getTotalMilesProtected,
  getVehicleEventExceptionSummary,
  getHighEventVehicleTrip,
  getLowEventVehicleTrip,
  getEventVideoInformation,
  getEventVideoSpeedLocation,
  getEventVideoGyroInformation,
  addCommonConfigurationSection,
  addCommonConfigurationName,
  addCommonConfigurationModule,
  updateCommonConfigurationName,
  updateCommonConfiguration,
  restoreFactorySettings,
  getLowEventVideoRequest,
  getWakeUpCondition,
  getWakeUpConditionTime,
  notificationReadAll,
  notificationRead,
  removeAllNotifications,
  removeNotifications,
  getNotifications,
  loginByToken,
  getRefreshToken,
  updateCommonConfigurationTemplateImei,
  editCommonConfiguration,
  getGlobalSearchData,
  verifyDevice,
  addCommonConfiguration,
  assignConfiguration,
  getLocation,
  uploadProfilePicture,
  uploadPicture,
  tripRecalculate,
  updatePassword,
  forgotPassword,
  resetPassword,
  requested_video,
};
