import { useContext, useEffect, useRef, useState } from "react";
import { Row, Input, Button, Spinner } from "reactstrap";
import { DatePicker, Tooltip } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import Select from "react-select";
import { Field, Formik } from "formik";
import { toast } from "react-toastify";
import { convertDateToTimestamp } from "../../../../../../services/common.functions.services";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../redux/store/store";
import { endLoading, startLoading } from "../../../../../../redux/reducers/general/general.actions";
import { getDeviceListForProj, getProjectListDD } from "../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { getVehicleJourney, resetMapData } from "../../../../../../redux/reducers/MapReducer/map.actions";
import { vehicleJourneyValidationSchema } from "../../../../../../constants";
import { LayoutContext } from "../../../../../../context/LayoutContext";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const { RangePicker } = DatePicker;

interface VehicleJourneyProps {
  setSelectedProjectId: (d: $TSFixMe) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & VehicleJourneyProps;

function VehicleJourney(props: Props) {
  const { selectedTheme, selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [selectedFilter, setSelectedFilter] = useState({
    projectId: null,
    vehicleRegNo: null,
    date: null,
  });
  const formikRef: $TSFixMe = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const apiCall = async () => {
    props.startLoading();
    if (props.dropdowns.projectListDD && props.dropdowns.projectListDD?.result?.length === 0) {
      props.getProjectListDD();
    }
    props.endLoading();
  };

  const handleFilter = async (values: $TSFixMe) => {
    try {
      setIsLoading(true);
      let data = {
        projectId: values?.project?.id,
        deviceId: values?.vehicleReg?.otherValue1,
        startTimestamp: values.date[0] ? convertDateToTimestamp(values?.date[0], props?.user?.timeZone?.zoneId) : null,
        endTimestamp: values.date[1] ? convertDateToTimestamp(values?.date[1], props?.user?.timeZone?.zoneId) : null,
      };
      await props.getVehicleJourney(data);
    } catch (error) {
      console.log(error);
      toast.error(`${(error as $TSFixMe).data.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedFilter.projectId) {
      props.setSelectedProjectId(selectedFilter.projectId);
      props.getDeviceListDD(selectedFilter.projectId);
    }
    return () => { }
  }, [selectedFilter.projectId]);

  useEffect(() => {
    if (!selectedFilter.projectId) {
      props.resetMapData();
    }
    return () => {
      props.resetMapData();
    };
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedProject) {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          projectId: selectedProject?.id,
          vehicleRegNo: null,
        };
      });
      formikRef.current.setFieldValue("vehicleReg", {});
    }
    return () => { }
  }, [selectedProject]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className={`d-flex align-items-start justify-content-center w-100 mx-auto`}>
      <Formik
        validationSchema={vehicleJourneyValidationSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          vehicleReg: {},
          project: selectedProject ?? {},
          date: "",
        }}
        onSubmit={handleFilter}
      >
        {({ handleBlur, setFieldValue, handleSubmit,
          isSubmitting,
          touched,
          setTouched,
          errors, }) => (
          <>
            <div className="w-25 mr-2">
              <Select
                placeholder="Project"
                className={`${touched.project && errors.project ? "requireSelect" : ""}`}
                options={props.dropdowns.projectListDD?.result ?? []}
                getOptionLabel={(option: $TSFixMe) => option.name}
                classNamePrefix={"filter-select-style"}
                getOptionValue={(option: $TSFixMe) => option.id.toString()}
                onChange={(option: $TSFixMe) => {
                  setTouched({ ...touched, project: false });
                  setSelectedFilter((prev) => {
                    return { ...prev, projectId: option.id, vehicleRegNo: null }
                  });
                  setFieldValue("project", option);
                  setFieldValue("vehicleReg", {});
                }}
                value={
                  selectedFilter.projectId
                    ? props.dropdowns.projectListDD?.result?.find(
                      (option: any) => option.id === selectedFilter.projectId
                    )
                    : null
                }
                onBlur={handleBlur("project")}
                maxMenuHeight={180}
              />
              {touched.project && errors.project && (
                <div className="validate">
                  {errors.project ? "Required" : errors.project}
                </div>
              )}
            </div>
            <div className="mx-2 w-25">
              <Select
                className={`${touched.vehicleReg && errors.vehicleReg ? "requireSelect" : ""}`}
                placeholder="Vehicle Reg#"
                options={props?.dropdowns?.device_proj?.result || []}
                getOptionLabel={(option: $TSFixMe) => option.name ?? option.otherValue1}
                classNamePrefix={"filter-select-style"}
                getOptionValue={(option: $TSFixMe) => option.id.toString()}
                onChange={(option: $TSFixMe) => {
                  setSelectedFilter((prev) => {
                    return { ...prev, vehicleRegNo: option.id }
                  });
                  setFieldValue("vehicleReg", option);
                }}
                value={
                  selectedFilter.vehicleRegNo
                    ? props?.dropdowns?.device_proj?.result?.find(
                      (option: any) => option.id === selectedFilter.vehicleRegNo
                    )
                    : null
                }
                onBlur={handleBlur("vehicleReg")}
                maxMenuHeight={180}
              />
              {touched.vehicleReg && errors.vehicleReg && (
                <div className="validate">
                  {errors.vehicleReg ? "Required" : errors.vehicleReg}
                </div>
              )}
            </div>
            <div className="mx-2 w-40">
              <Field
                component={RangePicker}
                name="date"
                dropdownClassName={(selectedTheme === "claret_blue" || selectedTheme === "black_pink_purple") ? "custom-date-range-picker" : selectedTheme}
                className={`w-100 rangeHei ${(errors.date && touched.date ? "is-invalid " : "")}`}
                onChange={(date: $TSFixMe, dateString: $TSFixMe) => {
                  if (dateString[0] !== "") {
                    setSelectedFilter((prev) => {
                      return { ...prev, date: dateString }
                    });
                    setFieldValue("date", dateString);
                  } else {
                    setSelectedFilter((prev) => {
                      return { ...prev, date: null }
                    });
                    setFieldValue("date", null);
                  }
                }}
                onBlur={handleBlur("date")}
                format="MM-DD-YYYY HH:mm:ss"
                showTime
                inputProps={{
                  onBlur: handleBlur("date"),
                }}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base: $TSFixMe) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                }}
              />
              {touched.date && errors.date && (
                <div className="validate">
                  {errors.date ? "Required" : errors.date}
                </div>
              )}
            </div>
            {/* <div className="mapsDropRight w-10"> */}
            {/* <Tooltip title="* Accuracy is in seconds and it is interval between two GPS
                  location.">
                  <Input
                    className=""
                    autoComplete="disable"
                    placeholder="2"
                    style={{ width: "100%" }}
                  />
                </Tooltip> */}
            {/* </div> */}
            <div className="w-10">
              <div onClick={() => handleSubmit()} className="header-icons-btn mx-auto">
                <Tooltip title={"Filter"}
                  align={{
                    points: ['bc', 'tc'], // Align tooltip top-center to button bottom-center
                    offset: [0, 5], // Adjust the offset (x, y)
                    targetOffset: [0, 0], // Adjust the target offset
                  }}
                  overlayInnerStyle={{ fontSize: '12px', padding: '3px 8px', minHeight: '22px' }}>

                  {isLoading ? <div className="header-icons d-flex align-items-center justify-content-center"><Spinner color="light"
                    size="sm" /></div> : <FilterAltIcon className="header-icons" />}
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

//export default VehicleJourney;
const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDeviceListDD: (id: number) => dispatch(getDeviceListForProj(id)),
  getProjectListDD: () => dispatch(getProjectListDD()),
  getVehicleJourney: (data: $TSFixMe) => dispatch(getVehicleJourney(data)),
  resetMapData: () => dispatch(resetMapData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleJourney);
