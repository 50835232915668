import { useEffect, useState } from "react";
//@ts-expect-error
import Editable from "react-text-content-editable";
import { makeStyles, Theme, withStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import AccordionActions from "@material-ui/core/AccordionActions";
import ToggleButton from "../../../../../../../../Common/ToggleButton";
import { THEME_TYPE } from "../../../../../../../../../constants";

import InputBase from "@material-ui/core/InputBase";
import Slider from "@material-ui/core/Slider";

import Select, { SingleValue } from "react-select";
import { Col, Row, Label, FormGroup, Input, Button } from "reactstrap";
import COLORS from "../../../../../../../../../colors";
import { connect } from "react-redux";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../../../redux/store/store";
import { AddCommonConfigurationTemplateType } from "../../../../../../../../../../global.types";
import {
  addCommonConfiguration,
  editCommonConfiguration,
  getWakeUpCondition,
  getWakeUpConditionTime,
  restoreFactorySettings,
  updateCommonConfigurationTemplateImei,
} from "../../../../../../../../../api/axios.resources";
import { ROUTES } from "../../../../../../../../../routes/routes";
import {
  endLoading,
  startLoading,
} from "../../../../../../../../../redux/reducers/general/general.actions";

interface Options1 {
  value: string;
  label: string;
}

// @ts-expect-error TS(2345): Argument of type '(theme: Theme) => { root: { widt... Remove this comment to see the full error message
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "20%",
    fontWeight: "600",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const PrettoSlider = withStyles(() => ({
  root: {
    color: COLORS.pureMostlyPureBlue,
    height: 8,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: COLORS.blue,
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-100% + 1px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
}))(Slider);

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    configurationData?: Partial<AddCommonConfigurationTemplateType>;
    editViewData?: AddCommonConfigurationTemplateType;
    mode?: "edit" | "view";
    theme: string;
    imei?: boolean;
    id?: number;
    setNameError: (error: string) => void;
  };

function EditAccordion(props: Props) {
  const [online, setonline] = useState(false);
  const [online1, setonline1] = useState(false);
  const [options, setOptions] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [options1, setOptions1] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [options2, setOptions2] = useState<Options1[]>([
    {
      value: "",
      label: "",
    },
  ]);
  const [errors, setErrors] = useState({}); // State to hold validation errors
  const [expanded, setExpanded] = useState("");
  const history = useHistory();
  const classes = useStyles();
  const { configurationData } = props;

  const [submittedAccordions, setSubmittedAccordions]: $TSFixMe = useState({
    aggregatePackageSetting: false,
    apnSetting: false,
    audioConfiguration: false,
    axisDetailsConfiguration: false,
    axisValue: false,
    cameraConfiguration: false,
    driverRFIdConfiguration: false,
    dataPackage: false,
    partsDetail: false,
    restoreFactorySetting: false,
    roamingSetting: false,
    sleepDelay: false,
    speedConfiguration: false,
    videoConfiguration: false,
    warrantyOption: false,
    name: true,
    description: true,
    status: true,
    common: true,
    id: true,
  });

  const [formData, setFormData] = useState<AddCommonConfigurationTemplateType>({
    aggregatePackageSetting: {
      aggregatePackage: false,
    },
    apnSetting: {
      apn: "",
      mcc: "",
      mnc: "",
      name: "",
      password: "",
      userName: "",
    },
    audioConfiguration: {
      recordAudio: false,
    },
    axisDetailsConfiguration: {
      detailedVehicleAxisData: false,
    },
    axisValue: {
      highAxisValue: {
        acceleration: 0,
        braking: 0,
        bump: 0,
        fall: 0,
        leftCornering: 0,
        rightCornering: 0,
      },
      lowAxisValue: {
        acceleration: 0,
        braking: 0,
        bump: 0,
        fall: 0,
        leftCornering: 0,
        rightCornering: 0,
      },
    },
    cameraConfiguration: {
      fifthCameraChannel: false,
      // firstCameraChannel: false,
      fourthCameraChannel: false,
      secondCameraChannel: false,
      thirdCameraChannel: false,
    },
    driverRFIdConfiguration: {
      driverRFID: false
    },
    dataPackage: {
      dataPackageId: 0,
    },
    description: configurationData?.description ?? "",
    name: configurationData?.name ?? "",
    parkModeSetting: {
      wakeUpConditionId: 1,
      wakeUpMode: false,
    },
    partsDetail: {
      cabling: "",
      driverPassengerCameras: "",
      hdd: "",
      hub: "",
      monitor: "",
      reversingCamera: "",
      sideCamera: "",
      sideSensorKit: "",
      streetangelct: "",
      suziKit: "",
    },
    restoreFactorySetting: {
      level: 1,
    },
    roamingSetting: {
      roaming: false,
    },
    sleepDelay: {
      allowUseOfVehicleBetteryAfterIgnitionOff: false,
      wakeUpConditionTimeId: 1,
    },
    speedConfiguration: {
      high: online,
      highSpeedGreterThan: 0,
      highSpeedLessThan: 0,
      low: online1,
      lowSpeedGreterThan: 0,
      lowSpeedLessThan: 0,
    },
    status: configurationData?.status ?? false,
    videoConfiguration: {
      videoPerOffset: 0,
      videoPostOffset: 0,
    },
    warrantyOption: {
      warrantyOptionId: 1,
    },
  });

  const apiCall = async () => {
    try {
      props.startLoading();
      const res1 = await restoreFactorySettings();
      let val1: Options1[] = res1.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions(val1);
      const res2 = await getWakeUpCondition();
      let val2: Options1[] = res2.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions1(val2);
      const res3 = await getWakeUpConditionTime();
      let val3: Options1[] = res3.result.map((item: $TSFixMe) => ({
        value: item.id,
        label: item.name,
      }));
      setOptions2(val3);
    } catch (error) {
      console.log("error", error);
    } finally {
      props.endLoading();
    }
  };

  const validatePartsDetail = (partsDetail: $TSFixMe) => {
    const errors: $TSFixMe = {};

    if (!partsDetail.cabling) {
      errors.partsDetailCabling = "Cabling is required";
    }
    if (!partsDetail.driverPassengerCameras) {
      errors.partsDetailDriverPassengerCameras =
        "Driver Passenger Cameras is required";
    }
    if (!partsDetail.hdd) {
      errors.partsDetailHdd = "HDD is required";
    }
    if (!partsDetail.hub) {
      errors.partsDetailHub = "Hub is required";
    }
    if (!partsDetail.monitor) {
      errors.partsDetailMonitor = "Monitor is required";
    }
    if (!partsDetail.reversingCamera) {
      errors.partsDetailReversingCamera = "Reversing Camera is required";
    }
    if (!partsDetail.sideCamera) {
      errors.partsDetailSideCamera = "Side Camera is required";
    }
    if (!partsDetail.sideSensorKit) {
      errors.partsDetailSideSensorKit = "Side Sensor Kit is required";
    }
    if (!partsDetail.streetangelct) {
      errors.partsDetailStreetangelct = "Road devil is required";
    }
    if (!partsDetail.suziKit) {
      errors.partsDetailSuziKit = "Suzi Kit is required";
    }

    return errors;
  };

  const validateApnSetting = (apnSetting: $TSFixMe) => {
    const errors: $TSFixMe = {};

    if (!apnSetting.name) {
      errors.apnSettingName = "Name is required";
    }
    if (!apnSetting.mcc) {
      errors.apnSettingMcc = "MCC is required";
    }
    if (!apnSetting.mnc) {
      errors.apnSettingMnc = "MNC is required";
    }
    if (!apnSetting.apn) {
      errors.apnSettingApn = "APN is required";
    }
    if (!apnSetting.userName) {
      errors.apnSettingUserName = "User Name is required";
    }
    if (!apnSetting.password) {
      errors.apnSettingPassword = "Password is required";
    }

    return errors;
  };

  const handleChange =
    (panel: $TSFixMe) => (event: $TSFixMe, isExpanded: $TSFixMe) => {
      setExpanded(isExpanded ? panel : "");
    };

  const handleAccordionSubmit = (panel: string) => {
    if (panel === "partsDetail") {
      const errors = validatePartsDetail(formData.partsDetail);
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
    } else if (panel === "apnSetting") {
      const errors = validateApnSetting(formData.apnSetting);
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      }
    }
    setSubmittedAccordions((prevSubmitted: $TSFixMe) => ({ ...prevSubmitted, [panel]: true }));

    setExpanded("");
  };

  const handleInputChange = (panel: string) => (name: string, value: string | boolean | number) => {
    // Capitalize the first letter of the name
    const capitalizedFirstName = name.charAt(0).toUpperCase() + name.slice(1);

    // Clear the corresponding error when the user enters a value
    setErrors((prevErrors: $TSFixMe) => {
      // Create a new object without the specific property
      const { [panel + capitalizedFirstName]: omit, ...restErrors } = prevErrors;
      return restErrors;
    });

    setFormData((prevFormData: $TSFixMe) => {
      // Clone the previous formData to avoid mutating the state directly
      let updatedFormData = { ...prevFormData };

      // Split the name string to access nested properties
      const nameParts = name?.split(".");
      // Traverse the nested properties one by one
      let target = updatedFormData[panel];

      for (let i = 0; i < (nameParts?.length ?? 0) - 1; i++) {
        if (!target) {
          // Initialize the intermediate property as an empty object if it's undefined
          target = {};
          updatedFormData[panel] = target;
        }
        target = target[nameParts[i]];
      }

      if (nameParts && target) {
        // Update the final property with the provided value
        target[nameParts[nameParts.length - 1]] = value;
      }

      return updatedFormData;
    });
  };

  const handleSubmit = async () => {

    // const validationErrors = validateFormData(formData);
    // if (validationErrors && Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    //   // Find the first field with an error and get its corresponding panel key
    //   for (const fieldName in validationErrors) {
    //     if (fieldName.startsWith("apnSetting")) {
    //       await apnSettingNameRef.current.scrollIntoView({ behavior: "smooth" });
    //       setExpanded("panel2"); // For the "apnSetting" panel
    //       return;
    //     } else if (fieldName.startsWith("partsDetail")) {
    //       await partsDetailCablingRef.current.scrollIntoView({ behavior: "smooth" });
    //       setExpanded("panel8"); // For the "partsDetail" panel
    //       return;
    //     }
    //   }
    //   toast.error("Please fill all the fields before submitting.");
    // }
    if (errors && Object.keys(errors).length > 0) {
      toast.error("Please fill all the fields before submitting.");
      return;
    }
    else {
      if (configurationData?.name === "") {
        props.setNameError("Name is required");
        return;
      }


      try {
        props.startLoading();
        const submittedData: $TSFixMe = Object.keys(submittedAccordions).reduce((acc: $TSFixMe, accordion: $TSFixMe) => {
          // @ts-ignore
          if (submittedAccordions[accordion]) {
            // @ts-ignore
            acc[accordion] = formData[accordion] as any; // Use type assertion here
          }
          return acc;
        }, {});
        if (props.mode === "edit") {
          await editCommonConfiguration(submittedData)
            .then((res) => {
              props.endLoading();
              if (res.status === 201) {
                toast.success(res.message);
              }
            })
            .then(() => {
              history.push(ROUTES.COMMON_CONFIGURATION);
            });

        } else {
          addCommonConfiguration(submittedData)
            .then((res: $TSFixMe) => {
              props.endLoading();
              if (res.status === 201) {
                toast.success("Configuration Created Successfully");
              }
            })
            .then(() => {
              history.push(ROUTES.COMMON_CONFIGURATION);
            });
        }
      } catch (err: any) {
        console.log(err);
      } finally {
        props.endLoading();
      }
    }
  };

  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      description: configurationData?.description ?? "",
      name: configurationData?.name ?? "",
      status: configurationData?.status ?? false,
    }));
  }, [configurationData]);

  useEffect(() => {
    if (props.editViewData) {
      if (props.mode === "edit" || props.mode === "view") {
        // Filter out properties with null values
        const filteredData = Object.fromEntries(
          Object?.entries(props.editViewData).filter(([key, value]) => value !== null)
        );

        // Spread formData and then spread filteredData
        setFormData({ ...formData, ...filteredData });

        // Update submittedAccordions based on keys in filteredData
        setSubmittedAccordions((prevState: $TSFixMe) => {
          const updatedAccordions: $TSFixMe = { ...prevState };

          // Iterate over keys of submittedAccordions
          Object.keys(updatedAccordions).forEach((key) => {
            // Check if the key is present in filteredData
            if (filteredData.hasOwnProperty(key)) {
              updatedAccordions[key] = true;
            } else {
              updatedAccordions[key] = false;
            }
          });

          return updatedAccordions;
        });
      }
    }
  }, [props.editViewData]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className={((classes as $TSFixMe).root, "commonConfigurationAccord")}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className={`mt-md-0 mt-5 ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          className={`accordionSummary accordianTop mt-md-0 mt-5`}
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Aggregate Package Setting
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails pb-4">
          <>
            <Row className="accordionData">
              <Col md={3}>
                <div className="modinpulabel modlabel">Aggregate Package</div>
              </Col>
              <Col md={0} className="accordianAggregate mt-md-0 mt-1">
                <ToggleButton
                  checked={formData?.aggregatePackageSetting?.aggregatePackage ?? false}
                  disabled={props.mode === "view"}
                  onClick={(value: boolean) =>
                    handleInputChange("aggregatePackageSetting")(
                      "aggregatePackage",
                      value
                    )
                  }
                />
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("aggregatePackageSetting");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      {/* <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          className={`accordionSummary ${props.theme}`}
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Apn Setting
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <Row className="accordianApn">
              <Col md={6}>
                <FormGroup>
                  <div className="modinpulabel modlabel" htmlFor="name">
                    Name
                  </div>
                  <Input
                    autoComplete="disable"
                    className="datewidth"
                    type="text" // Assuming 'type' should be 'text' for name input
                    placeholder="Name"
                    disabled={props.mode === "view"}
                    value={formData?.apnSetting?.name ?? ""}
                    onChange={(event) =>
                      handleInputChange("apnSetting")(
                        "name",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="modinpulabel modlabel" htmlFor="mcc">
                    MCC
                  </div>

                  <Input
                    autoComplete="disable"
                    type="text" // Assuming 'type' should be 'text' for mcc input
                    placeholder="MCC"
                    disabled={props.mode === "view"}
                    value={formData?.apnSetting?.mcc ?? ""}
                    onChange={(event) =>
                      handleInputChange("apnSetting")("mcc", event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <div className="modinpulabel modlabel" htmlFor="mnc">
                    MNC
                  </div>

                  <Input
                    autoComplete="disable"
                    type="text" // Assuming 'type' should be 'text' for mnc input
                    placeholder="MNC"
                    disabled={props.mode === "view"}
                    value={formData?.apnSetting?.mnc ?? ""}
                    onChange={(event) =>
                      handleInputChange("apnSetting")("mnc", event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <div className="modinpulabel modlabel" htmlFor="apn">
                    APN
                  </div>

                  <Input
                    autoComplete="disable"
                    type="text" // Assuming 'type' should be 'text' for apn input
                    placeholder="APN"
                    disabled={props.mode === "view"}
                    value={formData?.apnSetting?.apn ?? ""}
                    onChange={(event) =>
                      handleInputChange("apnSetting")("apn", event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  //  @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message }
                  <div className="modinpulabel modlabel" htmlFor="user">
                    User
                  </div>

                  <Input
                    autoComplete="disable"
                    type="text" // Assuming 'type' should be 'text' for user input
                    placeholder="User"
                    disabled={props.mode === "view"}
                    value={formData?.apnSetting?.userName ?? ""}
                    onChange={(event) =>
                      handleInputChange("apnSetting")(
                        "userName",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  //  @ts-expect-error TS(2322): Type '{ children: string; className: string; for: ... Remove this comment to see the full error message }
                  <div className="modinpulabel modlabel" htmlFor="password">
                    Password
                  </div>
                  <Input
                    type="text"
                    placeholder="Password"
                    disabled={props.mode === "view"}
                    autoComplete="disable"
                    value={formData?.apnSetting?.password ?? ""}
                    onChange={(event) =>
                      handleInputChange("apnSetting")(
                        "password",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("apnSetting");
                }}
                className={`${props.theme}-bg accordbut mb-sm-0 mb-2`}
              >
                Submit
              </Button>
              <Button className={`${props.theme}-bg accordbut`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion> */}

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Audio Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <Row>
              <Col xs={4} className="modinpulabel modlabel">
                <>Record Audio</>
              </Col>
              <Col xs={1} className="accordianRoaming mt-md-0 mt-1">
                <ToggleButton
                  checked={formData?.audioConfiguration?.recordAudio ?? false}
                  disabled={props.mode === "view"}
                  onClick={(value: boolean) => {
                    handleInputChange("audioConfiguration")(
                      "recordAudio",
                      value
                    );
                  }}
                />
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("audioConfiguration");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Axis Details Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <Row className="accordionData">
              <Col md={3}>
                <div className="modinpulabel modlabel">
                  Detailed Vehicle AxisData
                </div>
              </Col>
              <Col md={0} className="accordianRoaming mt-md-0 mt-1">
                <ToggleButton
                  checked={
                    formData?.axisDetailsConfiguration?.detailedVehicleAxisData ??
                    false
                  }
                  disabled={props.mode === "view"}
                  onClick={(value: boolean) => {
                    handleInputChange("axisDetailsConfiguration")(
                      "detailedVehicleAxisData",
                      value
                    );
                  }}
                />
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("axisDetailsConfiguration");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Axis Value
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <>
              <Row>
                <Col>
                  <div className="label-light">
                    <span className="accordionBold">LOW AXIS VALUE(G)*</span>{" "}
                    Ideal Value For This Field Is 0.4
                  </div>
                </Col>
              </Row>
            </>
            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.lowAxisValue?.braking ?? 0}
                    disabled={props.mode === "view"}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "lowAxisValue.braking",
                        value as number
                      );
                    }}
                    step={0.1}
                    min={0}
                    max={2}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Braking
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                    value={formData?.axisValue?.lowAxisValue?.acceleration}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "lowAxisValue.acceleration",
                        value as number
                      );
                    }}
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Acceleration
                  </div>
                </Col>
              </Row>
            </>
            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                    value={formData?.axisValue?.lowAxisValue?.leftCornering}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "lowAxisValue.leftCornering",
                        value as number
                      );
                    }}
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Left Cornering
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                    value={formData?.axisValue?.lowAxisValue?.rightCornering}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "lowAxisValue.rightCornering",
                        value as number
                      );
                    }}
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Right Cornering
                  </div>
                </Col>
              </Row>
            </>
            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                    value={formData?.axisValue?.lowAxisValue?.fall}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "lowAxisValue.fall",
                        value as number
                      );
                    }}
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Fall
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                    value={formData?.axisValue?.lowAxisValue?.bump}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "lowAxisValue.bump",
                        value as number
                      );
                    }}
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Bump
                  </div>
                </Col>
              </Row>
            </>

            <br />
            <>
              <Row>
                <Col>
                  <div className="label-light mb-2">
                    <span className="accordionBold mt-md-0 mt-2">
                      HIGH AXIS VALUE(G)*
                    </span>{" "}
                    Ideal Value For This Field Is 0.9
                  </div>
                </Col>
              </Row>
            </>
            <br />
            <>
              <Row className="mt-3">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.highAxisValue?.braking}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "highAxisValue.braking",
                        value as number
                      );
                    }}
                    disabled={props.mode === "view"}
                    step={0.1}
                    min={0}
                    max={2}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Braking
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.highAxisValue?.acceleration}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "highAxisValue.acceleration",
                        value as number
                      );
                    }}
                    disabled={props.mode === "view"}
                    step={0.1}
                    min={0}
                    max={2}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Acceleration
                  </div>
                </Col>
              </Row>
            </>
            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.highAxisValue?.leftCornering}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "highAxisValue.leftCornering",
                        value as number
                      );
                    }}
                    disabled={props.mode === "view"}
                    step={0.1}
                    min={0}
                    max={2}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Left Cornering
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.highAxisValue?.rightCornering}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "highAxisValue.rightCornering",
                        value as number
                      );
                    }}
                    disabled={props.mode === "view"}
                    step={0.1}
                    min={0}
                    max={2}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Right Cornering
                  </div>
                </Col>
              </Row>
            </>
            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.highAxisValue?.fall}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "highAxisValue.fall",
                        value as number
                      );
                    }}
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Fall
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    value={formData?.axisValue?.highAxisValue?.bump}
                    onChange={(event: any, value: number | number[]) => {
                      handleInputChange("axisValue")(
                        "highAxisValue.bump",
                        value as number
                      );
                    }}
                    step={0.1}
                    min={0}
                    max={2}
                    disabled={props.mode === "view"}
                    valueLabelDisplay="on"
                  />
                  <div className="accordionDescrip accordianSliderDesc label-light">
                    Bump
                  </div>
                </Col>
              </Row>
            </>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("axisValue");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Camera Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <div className="datewidth">
            <Row className="accordionData">
              {/* <Col xs={12} md={6}>
                  <Editable
                  tag="p"
                  type="text1 label-light"
                  maxLength="20"
                  // onChange={onChange1}
                    value={text1}
                  /> */}
              {/* <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                <div className="toggleConfig modinpulabel modlabel">
                  First Camera Channel (Front)
                </div>
                <div className="accordianSlider">
                  <ToggleButton
                    checked={formData?.cameraConfiguration?.firstCameraChannel ?? false}
                    disabled={props.mode === "view"}
                    onClick={(value: boolean) =>
                      handleInputChange("cameraConfiguration")(
                        "firstCameraChannel",
                        value
                      )
                    }
                  />
                </div>
              </Col> */}
              <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                <div className="toggleConfig  modinpulabel modlabel">
                  Second Camera Channel
                </div>
                <div className="accordianSlider">
                  <ToggleButton
                    disabled={props.mode === "view"}
                    checked={formData?.cameraConfiguration?.secondCameraChannel ?? false}
                    onClick={(value: boolean) =>
                      handleInputChange("cameraConfiguration")(
                        "secondCameraChannel",
                        value
                      )
                    }
                  />
                </div>
              </Col>
              <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                <div className="toggleConfig modinpulabel modlabel">
                  Third Camera Channel
                </div>
                <div className="accordianSlider">
                  <ToggleButton
                    disabled={props.mode === "view"}
                    checked={formData?.cameraConfiguration?.thirdCameraChannel ?? false}
                    onClick={(value: boolean) =>
                      handleInputChange("cameraConfiguration")(
                        "thirdCameraChannel",
                        value
                      )
                    }
                  />
                </div>
              </Col>
              <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                <div className="toggleConfig modinpulabel modlabel">
                  Fourth Camera Channel
                </div>
                <div className="accordianSlider">
                  <ToggleButton
                    disabled={props.mode === "view"}
                    checked={formData?.cameraConfiguration?.fourthCameraChannel ?? false}
                    onClick={(value: boolean) =>
                      handleInputChange("cameraConfiguration")(
                        "fourthCameraChannel",
                        value
                      )
                    }
                  />
                </div>
              </Col>
              <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                <div className="toggleConfig modinpulabel modlabel">
                  Fifth Camera Channel
                </div>
                <div className="accordianSlider">
                  <ToggleButton
                    disabled={props.mode === "view"}
                    checked={formData?.cameraConfiguration?.fifthCameraChannel ?? false}
                    onClick={(value: boolean) =>
                      handleInputChange("cameraConfiguration")(
                        "fifthCameraChannel",
                        value
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("cameraConfiguration");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      {/* <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Data Package
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <div className="accordianWarranty d-flex align-items-center w-100">
              <div className="mr-5">
                <Input
                  type="radio"
                  name="customRadio0"
                  id="monthly"
                  className="warranty-radio-btn"
                  disabled={props.mode === "view"}
                  checked={formData?.dataPackage?.dataPackageId === 0 ?? false}
                  onChange={() =>
                    handleInputChange("dataPackage")("dataPackageId", 0)
                  }
                />
                <label className="customcamera  label-light cursor-pointer" htmlFor="monthly">Monthly DD</label>
              </div>
              <div className="mr-5">
                <Input
                  type="radio"
                  name="customRadio1"
                  className="warranty-radio-btn"
                  id="year-one"
                  disabled={props.mode === "view"}
                  checked={formData?.dataPackage?.dataPackageId === 1 ?? false}
                  onChange={() =>
                    handleInputChange("dataPackage")("dataPackageId", 1)
                  }
                />
                <label className="customcamera  label-light cursor-pointer" htmlFor="year-one">
                  1 Year Data Package
                </label>
              </div>
              <div className="mr-5">
                <Input
                  type="radio"
                  name="customRadio2"
                  className="warranty-radio-btn"
                  id="year-two"
                  disabled={props.mode === "view"}
                  checked={formData?.dataPackage?.dataPackageId === 2 ?? false}
                  onChange={() =>
                    handleInputChange("dataPackage")("dataPackageId", 2)
                  }
                />
                <label className="customcamera  label-light cursor-pointer" htmlFor="year-two">
                  2 Year Data Package
                </label>
              </div>
              <div className="mr-5">
                <Input
                  type="radio"
                  name="customRadio3"
                  className="warranty-radio-btn"
                  id="year-three"
                  disabled={props.mode === "view"}
                  checked={formData?.dataPackage?.dataPackageId === 3 ?? false}
                  onChange={() =>
                    handleInputChange("dataPackage")("dataPackageId", 3)
                  }
                />
                <label className="customcamera  label-light cursor-pointer" htmlFor="year-three">
                  3 Year Data Package
                </label>
              </div>
            </div>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("dataPackage");
                }}
                className={`${props.theme}-bg accordbut mb-sm-0 mb-2`}
              >
                Submit
              </Button>
              <Button className={`${props.theme}-bg accordbut`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion> */}

      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Park Mode Setting
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <Row>
              <Col md={5}>
                <Row>
                  <Col sm={6}>
                    <p className="mt-md-3 label-light">Wakeup Mode</p>
                  </Col>
                  <Col sm={6}>
                    <ToggleButton
                      checked={formData?.parkModeSetting?.wakeUpMode ?? false}
                      disabled={props.mode === "view"}
                      onClick={(value: boolean) =>
                        handleInputChange("parkModeSetting")(
                          "wakeUpMode",
                          value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <Row>
                  <Col sm={12} className="mt-md-0 mt-5 ml-md-5">
                    <p className="label-light">Wake up Condition</p>
                    <Select
                      getOptionLabel={(option: Options1) => option.label}
                      getOptionValue={(option: Options1) => option.value}
                      isDisabled={props.mode === "view"}
                      options={options1}
                      value={options1.find(
                        (option: Options1) =>
                          option.value ==
                          formData?.parkModeSetting?.wakeUpConditionId.toString()
                      )}
                      classNamePrefix={"filter-select-style"}
                      onChange={(selectedOption: SingleValue<Options1>) =>
                        handleInputChange("parkModeSetting")(
                          "wakeUpConditionId",
                          selectedOption ? Number(selectedOption.value) : 0
                        )
                      }
                      placeholder="Wakeup on external power"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("parkModeSetting");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Roaming Setting
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="accordianDetails">
          <>
            <Row>
              <Col xs={4} className="modinpulabel modlabel">
                <>Roaming</>
              </Col>
              <Col xs={1} className="accordianRoaming mt-md-0 mt-1">
                <ToggleButton
                  checked={formData?.roamingSetting?.roaming ?? false}
                  disabled={props.mode === "view"}
                  onClick={(value: boolean) =>
                    handleInputChange("roamingSetting")("roaming", value)
                  }
                />
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("roamingSetting");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      {/* <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Restore Factory Settings
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <div className="accordion11">
            <Row>
              <Col md={6}>
                <div className="modinpulabel modlabel mb-2">Select Level</div>
                <Select
                  getOptionLabel={(option: Options1) => option.label}
                  getOptionValue={(option: Options1) => option.value}
                  isDisabled={props.mode === "view"}
                  options={options}
                  classNamePrefix={"vd-select-dropdown"}
                  value={options.find(
                    (option) =>
                      option.value ==
                      formData?.restoreFactorySetting?.level.toString()
                  )}
                  onChange={(selectedOption: SingleValue<Options1>) =>
                    handleInputChange("restoreFactorySetting")(
                      "level",
                      selectedOption ? selectedOption.value : 0
                    )
                  }
                />
              </Col>
            </Row>
          </div>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("restoreFactorySetting");
                }}
                className={`${props.theme}-bg accordbut mb-sm-0 mb-2`}
              >
                Submit
              </Button>
              <Button className={`${props.theme}-bg accordbut`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion> */}

      <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Sleep Delay
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <div className="modinpulabel accordionleft">
              * This Setting is Supported Only In road devil Firmware's Device.
            </div>
            <br />
            <Row className="accordionSlider">
              <Col md={6} className="accordionleft">
                <div className="modlabel modinpulabel mb-1">
                  Wake up Condition
                </div>
                <Select
                  getOptionLabel={(option: Options1) => option.label}
                  getOptionValue={(option: Options1) => option.value}
                  isDisabled={props.mode === "view"}
                  options={options2}
                  classNamePrefix={"filter-select-style"}
                  value={options2.find(
                    (option) =>
                      option.value ===
                      formData?.sleepDelay?.wakeUpConditionTimeId.toString()
                  )}
                  onChange={(selectedOption: SingleValue<Options1>) =>
                    handleInputChange("sleepDelay")(
                      "wakeUpConditionTimeId",
                      selectedOption ? Number(selectedOption.value) : 0
                    )
                  }
                />
              </Col>
              <Col
                className="modlabel modinpulabel accordionleft mt-md-0 mt-2 ml-md-1"
                md={4}
              >
                <Row>
                  <Col md={10}>
                    <>Allow Use Of Vehicle's Battery After Ignition Off:</>
                  </Col>
                  <Col md={2}>
                    <ToggleButton
                      checked={
                        formData?.sleepDelay?.allowUseOfVehicleBetteryAfterIgnitionOff ?? false
                      }
                      disabled={props.mode === "view"}
                      onClick={(value: boolean) =>
                        handleInputChange("sleepDelay")(
                          "allowUseOfVehicleBetteryAfterIgnitionOff",
                          value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("sleepDelay");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      <Accordion
        expanded={expanded === "panel13"}
        onChange={handleChange("panel13")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Speed Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <>
            <>
              <Row>
                <Col md={6}>
                  <Row className="d-flex  align-items-center">
                    <Col xs={5} className="mt-2 label-light">
                      Low
                    </Col>
                    <Col xs={7}>
                      <ToggleButton
                        disabled={props.mode === "view"}
                        checked={formData?.speedConfiguration?.low ?? false}
                        // onClick={(value: $TSFixMe) => setonline(!online)}
                        onClick={(value: boolean) =>
                          handleInputChange("speedConfiguration")("low", value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="mt-md-0 mt-3">
                  <Row className="d-flex  align-items-center">
                    <Col xs={5} className="mt-2 label-light">
                      High
                    </Col>
                    <Col xs={7}>
                      <ToggleButton
                        disabled={props.mode === "view"}
                        checked={formData?.speedConfiguration?.high ?? false}
                        onClick={(value: boolean) =>
                          handleInputChange("speedConfiguration")("high", value)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>

            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={1}
                    min={0}
                    max={10}
                    disabled={props.mode === "view" || !formData?.speedConfiguration?.low}
                    valueLabelDisplay="on"
                    value={formData?.speedConfiguration?.lowSpeedLessThan}
                    onChange={(event, value) =>
                      handleInputChange("speedConfiguration")(
                        "lowSpeedLessThan",
                        value as number
                      )
                    }
                  />
                  <div className="dataModal accordianSliderDesc label-light">
                    Low Speed % (for less then 80KPH/50MPH)
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={1}
                    min={0}
                    max={10}
                    disabled={props.mode === "view" || !formData?.speedConfiguration?.high}
                    valueLabelDisplay="on"
                    value={formData?.speedConfiguration?.highSpeedLessThan}
                    onChange={(event, value) =>
                      handleInputChange("speedConfiguration")(
                        "highSpeedLessThan",
                        value as number
                      )
                    }
                  />
                  <div className="dataModal accordianSliderDesc label-light">
                    High Speed % (for less then 80KPH/50MPH)
                  </div>
                </Col>
              </Row>
            </>
            <>
              <Row className="mt-5">
                <Col md={6}>
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={1}
                    min={0}
                    max={10}
                    disabled={props.mode === "view" || !formData?.speedConfiguration?.low}
                    valueLabelDisplay="on"
                    value={formData?.speedConfiguration?.lowSpeedGreterThan}
                    onChange={(event, value) =>
                      handleInputChange("speedConfiguration")(
                        "lowSpeedGreterThan",
                        value as number
                      )
                    }
                  />
                  <div className="dataModal accordianSliderDesc label-light">
                    Low Speed % (for greater then 80KPH/50MPH)
                  </div>
                </Col>
                <Col md={6} className="mt-md-0 mt-5">
                  <PrettoSlider
                    aria-label="pretto slider"
                    step={1}
                    min={0}
                    max={10}
                    disabled={props.mode === "view" || !formData?.speedConfiguration?.high}
                    valueLabelDisplay="on"
                    value={formData?.speedConfiguration?.highSpeedGreterThan}
                    onChange={(event, value) =>
                      handleInputChange("speedConfiguration")(
                        "highSpeedGreterThan",
                        value as number
                      )
                    }
                  />
                  <div className="dataModal accordianSliderDesc label-light">
                    High Speed % (for greater then 80KPH/50MPH)
                  </div>
                </Col>
              </Row>
            </>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("speedConfiguration");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      <Accordion
        expanded={expanded === "panel14"}
        onChange={handleChange("panel14")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel14bh-content"
          id="panel14bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Video Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <div className="datewidth">
            <Row className="mt-5">
              <Col md={6}>
                <PrettoSlider
                  aria-label="pretto slider"
                  step={1}
                  min={0}
                  max={10}
                  disabled={props.mode === "view"}
                  valueLabelDisplay="on"
                  value={formData?.videoConfiguration?.videoPerOffset}
                  onChange={(event, value) =>
                    handleInputChange("videoConfiguration")(
                      "videoPerOffset",
                      value as number
                    )
                  }
                />
                <div className="dataModal accordianSliderDesc label-light">
                  VideoPreOffset(Seconds)
                </div>
              </Col>
              <Col md={6} className="mt-md-0 mt-5">
                <PrettoSlider
                  aria-label="pretto slider"
                  step={1}
                  min={0}
                  max={20}
                  disabled={props.mode === "view"}
                  valueLabelDisplay="on"
                  value={formData?.videoConfiguration?.videoPostOffset}
                  onChange={(event, value) =>
                    handleInputChange("videoConfiguration")(
                      "videoPostOffset",
                      value as number
                    )
                  }
                />
                <div className="dataModal accordianSliderDesc label-light">
                  VideoPostOffset(Seconds)
                </div>
              </Col>
            </Row>
          </div>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("videoConfiguration");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      {/* <Accordion
        expanded={expanded === "panel15"}
        onChange={handleChange("panel15")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel15bh-content"
          id="panel15bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Warranty Option
          </Typography>
        </AccordionSummary>

        <AccordionDetails className="accordianDetails">
          <>
            <Row className="accordianWarranty">
              <Col md={1}>
                <Input
                  type="radio"
                  name="customRadio"
                  className="warranty-radio-btn"
                  id="0-year"
                  disabled={props.mode === "view"}
                  checked={formData?.warrantyOption?.warrantyOptionId === 1 ?? false}
                  onChange={() =>
                    handleInputChange("warrantyOption")("warrantyOptionId", 1)
                  }
                />
                <label className="customcamera label-light cursor-pointer mb-0" htmlFor="0-year">0 Year</label>
              </Col>
              <Col md={1}>
                <Input
                  type="radio"
                  className="warranty-radio-btn"
                  name="customRadio"
                  id="1-year"
                  disabled={props.mode === "view"}
                  checked={formData?.warrantyOption?.warrantyOptionId === 2 ?? false}
                  onChange={() =>
                    handleInputChange("warrantyOption")("warrantyOptionId", 2)
                  }
                />
                <label className="customcamera label-light cursor-pointer mb-0" htmlFor="1-year">1 Year</label>
              </Col>
              <Col md={1}>
                <Input
                  type="radio"
                  name="customRadio"
                  className="warranty-radio-btn"
                  id="2-year"
                  disabled={props.mode === "view"}
                  checked={formData?.warrantyOption?.warrantyOptionId === 3 ?? false}
                  onChange={() =>
                    handleInputChange("warrantyOption")("warrantyOptionId", 3)
                  }
                />
                <label className="customcamera label-light cursor-pointer mb-0" htmlFor="2-year">2 Year</label>
              </Col>
              <Col md={1}>
                <Input
                  type="radio"
                  name="customRadio"
                  id="3-year"
                  className="warranty-radio-btn"
                  disabled={props.mode === "view"}
                  checked={formData?.warrantyOption?.warrantyOptionId === 4 ?? false}
                  onChange={() =>
                    handleInputChange("warrantyOption")("warrantyOptionId", 4)
                  }
                />
                <label className="customcamera label-light cursor-pointer mb-0" htmlFor="3-year">3 Year</label>
              </Col>
            </Row>
          </>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("warrantyOption");
                }}
                className={`${props.theme}-bg accordbut mb-sm-0 mb-2`}
              >
                Submit
              </Button>
              <Button className={`${props.theme}-bg accordbut`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion> */}

      <Accordion
        expanded={expanded === "panel16"}
        onChange={handleChange("panel16")}
        className={`accordionTop ${props.theme}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="accordionTrigger" />}
          aria-controls="panel16bh-content"
          id="panel16bh-header"
          className="accordionSummary"
        >
          <Typography className={(classes as $TSFixMe).heading}>
            Driver RFID Configuration
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="accordianDetails">
          <div className="datewidth">
            <Row className="accordionData">
              <Col md={6} className="accordianRoaming mb-2 d-flex mt-md-0 mt-1">
                <div className="toggleConfig  modinpulabel modlabel">
                  Driver RFID
                </div>
                <div className="accordianSlider">
                  <ToggleButton
                    disabled={props.mode === "view"}
                    checked={formData?.driverRFIdConfiguration?.driverRFID ?? false}
                    onClick={(value: boolean) =>
                      handleInputChange("driverRFIdConfiguration")(
                        "driverRFID",
                        value
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </AccordionDetails>
        <Divider className="accordionDivider" />
        <AccordionActions>
          {props.mode !== "view" && (
            <div className="divAlign2">
              <Button
                onClick={() => {
                  handleAccordionSubmit("driverRFIdConfiguration");
                }}
                className={`${props.theme}`}
              >
                Submit
              </Button>
              <Button className={`${props.theme} ml-3`}>Cancel</Button>
            </div>
          )}
        </AccordionActions>
      </Accordion>

      {props.mode !== "view" && (
        <div className="divAlign2">
          <Button
            onClick={handleSubmit}
            className={`${props.theme} mt-4`}
            disabled={props.isLoading}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.generalSlice.isLoading,
});
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAccordion);
