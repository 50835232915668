import { useState, useEffect, useContext } from "react";
import { Row, Col, Button, Spinner } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { monitoringValidationSchema } from "../../../../../../constants";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../redux/store/store";
import { endLoading, startLoading } from "../../../../../../redux/reducers/general/general.actions";
import { getProjectListDD } from "../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { getMapMonitoring, resetMapData } from "../../../../../../redux/reducers/MapReducer/map.actions";
import { LayoutContext } from "../../../../../../context/LayoutContext";
import { Tooltip } from "antd";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function Monitoring(props: Props) {
  const { selectedTheme, selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [selectedProjectId, setSelectedProjectId]: $TSFixMe = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const apiCall = async () => {
    props.startLoading();
    if (props.dropdowns.projectListDD && props.dropdowns.projectListDD?.result?.length === 0) {
      props.getProjectListDD();
    }
    props.endLoading();
  };

  const handleFilter: $TSFixMe = async (values: $TSFixMe, type?: $TSFixMe) => {
    try {
      if (type !== "noFormSubmit") {
        setIsLoading(true);
      }
      let data = {
        projectId: values.project.id,
      };
      await props.getMapMonitoring(data);
    } catch (error) {
      console.log(error);
      toast.error(`${(error as $TSFixMe).data.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!selectedProjectId) {
      props.resetMapData();
    }
    return () => {
      props.resetMapData();
    };
  }, [selectedProjectId]);

  useEffect(() => {
    if (selectedProject) {
      setSelectedProjectId(selectedProject?.id);
      const data = {
        project: selectedProject
      }
      handleFilter(data, "noFormSubmit");
    }
    return () => { }
  }, [selectedProject]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className={`d-flex align-items-start justify-content-center w-25 mx-auto`}>
      <Formik
        validationSchema={monitoringValidationSchema}
        enableReinitialize={true}
        initialValues={{
          project: selectedProject ?? {},
        }}
        onSubmit={handleFilter}
      >
        {({ handleBlur, setFieldValue, handleSubmit,
          isSubmitting,
          touched,
          errors, }) => (
          <>
            <div className="w-100 mr-2">
              <Select
                placeholder="Project"
                className={`${touched.project && errors.project ? "requireSelect" : ""}`}
                options={props.dropdowns.projectListDD?.result ?? []}
                getOptionLabel={(option) => option.name}
                classNamePrefix={"filter-select-style"}
                getOptionValue={(option) => option.id.toString()}
                onChange={(option: $TSFixMe) => {
                  setSelectedProjectId(option.id);
                  setFieldValue("project", option);
                }}
                value={
                  selectedProjectId
                    ? props.dropdowns.projectListDD?.result?.find(
                      (option: any) =>
                        option.id === selectedProjectId
                    )
                    : null
                }
                onBlur={handleBlur("project")}
                maxMenuHeight={180}
              />
              {touched.project && errors.project && (
                <div className="validate">
                  {errors.project ? "Required" : errors.project}
                </div>
              )}
            </div>
            <div className="w-10 ml-2">
              <div onClick={() => handleSubmit()} className="header-icons-btn ml-auto">
                <Tooltip title={"Filter"}
                  align={{
                    points: ['bc', 'tc'], // Align tooltip top-center to button bottom-center
                    offset: [0, 5], // Adjust the offset (x, y)
                    targetOffset: [0, 0], // Adjust the target offset
                  }}
                  overlayInnerStyle={{ fontSize: '12px', padding: '3px 8px', minHeight: '22px' }}>

                  {isLoading ? <div className="header-icons d-flex align-items-center justify-content-center"><Spinner color="light"
                    size="sm" /></div> : <FilterAltIcon className="header-icons" />}
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  organizationReports: state.dataStore.organizationReports,
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getProjectListDD: () => dispatch(getProjectListDD()),
  getMapMonitoring: (data: $TSFixMe) => dispatch(getMapMonitoring(data)),
  resetMapData: () => dispatch(resetMapData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);
