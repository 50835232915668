import { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Button, Spinner } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { Formik } from "formik";
import { liveTrackingSchema } from "../../../../../../constants";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../redux/store/store";
import { endLoading, startLoading } from "../../../../../../redux/reducers/general/general.actions";
import { getDeviceListForProj, getProjectListDD } from "../../../../../../redux/reducers/dropdowns/dropdown.actions";
import { getLiveTracking } from "../../../../../../redux/reducers/MapReducer/map.actions";
import { LayoutContext } from "../../../../../../context/LayoutContext";
import { Tooltip } from "antd";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function LiveTracking(props: Props) {
  const { selectedTheme, selectedProject }: $TSFixMe = useContext(LayoutContext);
  const [selectedFilter, setSelectedFilter] = useState({
    projectId: null,
    vehicleRegNo: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmittingFilter, setIsSubmittingFilter] = useState(false);
  const formikRef: $TSFixMe = useRef();

  const handleFilter: $TSFixMe = async (values: $TSFixMe, type?: string) => {
    try {
      if (type !== "noSubmit") {
        setIsLoading(true);
      }
      let data = {
        projectId: values.project.id,
        deviceId: values.vehicleReg.otherValue1,
      };
      await props.getLiveTracking(data);
      setTimeout(() => {
        setIsSubmittingFilter(!isSubmittingFilter);
      }, 2000);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const apiCall = async () => {
    props.startLoading();
    if (props.dropdowns.projectListDD && props.dropdowns.projectListDD?.result?.length === 0) {
      props.getProjectListDD();
    }
    props.endLoading();
  };


  useEffect(() => {
    if (selectedFilter.projectId) {
      props.getDeviceListDD(selectedFilter.projectId);
    }
    return () => { };
  }, [selectedFilter.projectId]);

  useEffect(() => {
    const values: $TSFixMe = {
      project: {
        id: selectedFilter.projectId
      },
      vehicleReg: {
        otherValue1: selectedFilter.vehicleRegNo
      },
    }
    let success = false; // Flag to track success of API call

    const fetchData = () => {
      if (selectedFilter.projectId && selectedFilter.vehicleRegNo) {
        handleFilter(values, "noSubmit");
      }
    };

    // Initial call when the submit button is clicked
    fetchData();

    // Set up the interval to call the API every 5 seconds
    const interval = setInterval(() => {
      if (success) { // Only call API if the previous call was successful
        fetchData();
      } else {
        clearInterval(interval); // Stop interval if previous call failed
      }
    }, 5000);

    // Clean up the interval when the component is unmounted or when the submit button is not pressed
    return () => clearInterval(interval);
  }, [isSubmittingFilter]);

  useEffect(() => {
    if (selectedProject) {
      setSelectedFilter((prev) => {
        return {
          ...prev,
          projectId: selectedProject?.id,
          vehicleRegNo: null,
        };
      });
      formikRef.current.setFieldValue("vehicleReg", {});
    }
    return () => { }
  }, [selectedProject]);

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className={`d-flex align-items-start justify-content-center w-50 mx-auto`}>
      <Formik
        validationSchema={liveTrackingSchema}
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          vehicleReg: {},
          project: selectedProject ?? {},
        }}
        onSubmit={handleFilter}
      >
        {({ handleBlur, setFieldValue, handleSubmit,
          isSubmitting,
          setTouched,
          touched,
          errors, }) => (
          <>
            <div className="w-40 mx-2">
              <Select
                className={`${touched.project && errors.project ? "requireSelect" : ""}`}
                placeholder="Project"
                classNamePrefix={"filter-select-style"}
                options={props.dropdowns.projectListDD?.result ?? []}
                getOptionLabel={(option: $TSFixMe) => option.name}
                getOptionValue={(option: $TSFixMe) => option.id.toString()}
                onChange={(option: $TSFixMe) => {
                  setTouched({ ...touched, project: false });
                  setSelectedFilter((prev) => {
                    return {
                      ...prev,
                      projectId: option.id,
                      vehicleRegNo: null,
                    };
                  });
                  setFieldValue("project", option);
                  setFieldValue("vehicleReg", {});
                }}
                value={
                  selectedFilter.projectId
                    ? props.dropdowns.projectListDD?.result?.find(
                      (option: any) =>
                        option.id === selectedFilter.projectId
                    )
                    : null
                }
                onBlur={handleBlur("project")}
                maxMenuHeight={180}
              />
              {touched.project && errors.project && (
                <div className="validate">
                  {errors.project ? "Required" : errors.project}
                </div>
              )}
            </div>
            <div className="w-40 mx-2">
              <Select
                className={`${touched.vehicleReg && errors.vehicleReg ? "requireSelect" : ""}`}
                placeholder="Vehicle Reg#"
                classNamePrefix={"filter-select-style"}
                options={props?.dropdowns?.device_proj?.result || []}
                getOptionLabel={(option: $TSFixMe) => option.name ?? option.otherValue1}
                getOptionValue={(option: $TSFixMe) => option.id.toString()}
                onChange={(option: $TSFixMe) => {
                  setSelectedFilter((prev: $TSFixMe) => {
                    return { ...prev, vehicleRegNo: option.otherValue1 };
                  });
                  setFieldValue("vehicleReg", option);
                }}
                value={
                  selectedFilter.vehicleRegNo
                    ? props?.dropdowns?.device_proj?.result?.find(
                      (option: any) =>
                        option.id === selectedFilter.vehicleRegNo
                    )
                    : null
                }
                onBlur={handleBlur("vehicleReg")}
                maxMenuHeight={180}
              />
              {touched.vehicleReg && errors.vehicleReg && (
                <div className="validate">
                  {errors.vehicleReg ? "Required" : errors.vehicleReg}
                </div>
              )}
            </div>
            <div className="w-10 ml-2">
              <div onClick={() => handleSubmit()} className="header-icons-btn ml-auto">
                <Tooltip title={"Filter"}
                  align={{
                    points: ['bc', 'tc'], // Align tooltip top-center to button bottom-center
                    offset: [0, 5], // Adjust the offset (x, y)
                    targetOffset: [0, 0], // Adjust the target offset
                  }}
                  overlayInnerStyle={{ fontSize: '12px', padding: '3px 8px', minHeight: '22px' }}>

                  {isLoading ? <div className="header-icons d-flex align-items-center justify-content-center"><Spinner color="light"
                    size="sm" /></div> : <FilterAltIcon className="header-icons" />}
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  dropdowns: state.dropdownList.dropdowns,
  isLoading: state.generalSlice.isLoading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
  getDeviceListDD: (id: number) => dispatch(getDeviceListForProj(id)),
  getProjectListDD: () => dispatch(getProjectListDD()),
  getLiveTracking: (data: $TSFixMe) => dispatch(getLiveTracking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveTracking);
