import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { endLoading, startLoading } from "../../../../redux/reducers/general/general.actions";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../redux/store/store";
import { GetDeviceStatus } from "../../../../../global.types";
import DataTable from "../../../Common/DataTable/dataTable";
import { Link } from "react-router-dom";
import VideoCallRoundedIcon from "@mui/icons-material/VideoCallRounded";
import { convertTimestampToDate, encrypt } from "../../../../services/common.functions.services";
import { resetDevice, videoRequest } from "../../../../api/axios.resources";
import { toast } from "react-toastify";
import { DEVICE_VIDEO_STATUS } from "../../../../constants";
import { ROUTES } from "../../../../routes/routes";
import PlayCircleOutlineSharpIcon from "@mui/icons-material/PlayCircleOutlineSharp";
import TimelapseSharpIcon from "@mui/icons-material/TimelapseSharp";
import { Tooltip } from "antd";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import usePermission from "../../../../constants/usePermission";
interface DeviceStatusTabProps {
  setRecordsPerPage: (d: number) => () => void;
  setSelectedPage: (d: number) => () => void;
  setSortColumn: (d: string) => () => void;
  setIsAsc: (d: boolean) => () => void;
  handleFilter: () => void;
  selectedPage: number;
  recordsPerPage: number;
  isPageLoading: boolean;
  setIsPageLoading: (d: boolean) => void;
}

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & DeviceStatusTabProps;

const DeviceStatusTab = (props: Props) => {
  const [deviceStatusList, setDeviceStatusList] = useState<GetDeviceStatus[]>([]);
  const isDeviceStatusReadWrite = usePermission("DEVICE_STATUS_WRITE");

  const DeviceStatusActionBtns2 = () => {
    return (
      <div className="tabAction">
        <input type="checkbox" />
      </div>
    );
  };

  const handleResetData = async (data: $TSFixMe) => {
    if (window.confirm("Are you sure you want to reset this device?")) {
      try {
        const res = await resetDevice({ deviceId: data?.deviceId, projectId: data?.projectId });
      } catch (error) {
        console.log(error);
      }
    }
  }

  const handleVideoRequest = async (data: $TSFixMe) => {
    try {
      const res = await videoRequest({ deviceId: data?.deviceId, projectId: data?.projectId });
      if (res.status === 200) {
        toast.success(res.message);
        props.handleFilter();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const LowEventsActionBtns4 = (data: $TSFixMe) => {
    const encryptedParams: $TSFixMe = encrypt({
      deviceId: data?.data?.management?.deviceId,
      id: data?.data?._id,
      projectId: data?.data?.management?.projectId,
      type: 'DEVICE_STATUS_VIDEO_INFO',
      isIgnore: true,
    });

    return (
      <div className="imptabbody">
        <Link to={`${ROUTES.HIGH_EVENTS_VIDEO}/${encryptedParams}`} className="tabLink lh-0" target="_blank">
          <PlayCircleOutlineSharpIcon className="dropico" />
        </Link>
      </div>
    );
  };

  const DeviceStatusActionBtns1 = (data: $TSFixMe) => {
    return (
      <div className="tabAction">
        <Tooltip title={"Reset device"} placement={"left"}>
          <ReplayOutlinedIcon className="dropico" onClick={() => handleResetData(data.data.management)} />
        </Tooltip>
        {data?.data?.command_info && (data?.data?.command_info?.status === DEVICE_VIDEO_STATUS.completed && Number(data?.data?.command_info?.responseCode) === 0) && (
          <LowEventsActionBtns4 data={data.data} />
        )}
        {data?.data?.command_info && (data?.data?.command_info?.status === DEVICE_VIDEO_STATUS.completed && Number(data?.data?.command_info?.responseCode) !== 0) && (
          <NotInterestedIcon className="nondropico" />
        )}
        {data?.data?.command_info && (data?.data?.command_info?.status === DEVICE_VIDEO_STATUS.pending) && (
          <Tooltip title={"Already, requested for video."} placement={"left"}>
            <TimelapseSharpIcon className="nondropico" />
          </Tooltip>
        )}
        {(Object.keys(data?.data?.command_info).length === 0 || data?.data?.command_info?.status !== DEVICE_VIDEO_STATUS.pending) &&
          <Tooltip title={"Video request"} placement={"left"}>
            <VideoCallRoundedIcon className="dropico" onClick={() => handleVideoRequest(data?.data?.management)} />
          </Tooltip>
        }
      </div>
    );
  };

  const deviceStatusColumns = [
    {
      title: "Vehicle Reg#",
      data: "management.vehicleRegNo", sorting: false,
      format: (data: $TSFixMe) => data?.management?.vehicleRegNo,
    },
    {
      title: "Device ID", data: "deviceId"
    },
    {
      title: "Organization",
      data: "management.organizationName", sorting: false,
      format: (data: $TSFixMe) => data?.management?.organizationName,
    },
    {
      title: "Project",
      data: "management.projectName", sorting: false,
      format: (data: $TSFixMe) => data?.management?.projectName,
    },
    { title: "Firmware Version", data: "login.firmwareVersion" },
    { title: "ICCID", data: "login.sim" },
    {
      title: "Latest Login Date",
      data: "login.timestamp",
      format: (data: $TSFixMe) => data?.login?.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.login.timestamp, null, props.user.timeFormat) : "N/A",
    },
    {
      title: "Latest Gps Date",
      format: (data: $TSFixMe) => data?.gps && data?.gps?.timestamp ? convertTimestampToDate(props.user.timeZone.zoneId, data.gps.timestamp, null, props.user.timeFormat) : "N/A",
    },
    // {
    //   title: "Select",
    //   format: (data: $TSFixMe) => <DeviceStatusActionBtns2 />,
    // },
  ];

  if (isDeviceStatusReadWrite) {
    deviceStatusColumns.push({
      title: " Action",
      data: "action", sorting: false,
      format: (data) => <DeviceStatusActionBtns1 data={data} />,
    },)
  }

  useEffect(() => {
    if (props.deviceStatus?.result) {
      setDeviceStatusList(props.deviceStatus?.result?.data || []);
    }
    return () => { };
  }, [props.deviceStatus]);


  return (
    <>
      <DataTable
        data={deviceStatusList}
        columns={deviceStatusColumns}
        bordered
        pageData={props.deviceStatus?.result?.page}
        totalRecrods={props.deviceStatus?.result?.totalRecrods}
        setPageSize={props.setRecordsPerPage}
        pageSize={props.recordsPerPage}
        setCurrentPage={props.setSelectedPage}
        currentPage={props.selectedPage}
        setSortColumn={props.setSortColumn}
        setIsAsc={props.setIsAsc}
        isPageLoading={props.isPageLoading}
        setIsPageLoading={props.setIsPageLoading}
        hover={true}
        responsive={true}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  deviceStatus: state.dataStore.deviceStatus,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoading: () => dispatch(startLoading()),
  endLoading: () => dispatch(endLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceStatusTab);
