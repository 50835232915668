import { useContext, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Spinner, Button } from "reactstrap";
import Loc from "./Components/loc/index";
import VideoGraph from "./Components/videoGraph/index";
import { useParams } from "react-router-dom";
import FullPageLoaderModal from "../../../../../../Common/FullPageLoader/FullPageLoaderModal";
import { connect } from "react-redux";
import { addChatdata } from "../../../../../../../redux/reducers/DataReducer/data.actions";
import { RootState } from "../../../../../../../redux/reducers/rootReducer";
import { AppDispatch } from "../../../../../../../redux/store/store";
import { LayoutContext } from "../../../../../../../context/LayoutContext";
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled';
import { addAdminNote, getAdminNote, getEventVideoGyroInformation, getEventVideoInformation, getEventVideoSpeedLocation } from "../../../../../../../api/axios.resources";
import { convertTimestampToDate, decrypt } from "../../../../../../../services/common.functions.services";
import InfiniteScroll from "react-infinite-scroll-component";
import { CSVLink } from "react-csv";
import { HignEventVideoDownloadColums } from "../../../../../../../constants";
import JSZip from 'jszip';
import axios from 'axios';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function HighEventsVideo(props: Props) {
  const { selectedTheme } = useContext(LayoutContext);
  const [inputText, setInputText] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [gyroInfo, setGyroInfo]: $TSFixMe = useState([])
  const [gyroLoading, setGyroLoading]: $TSFixMe = useState(false)
  const [videoInfo, setVideoInfo]: $TSFixMe = useState({})
  const [videoSpeedLocation, setVideoSpeedLocation]: $TSFixMe = useState({})
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [adminNote, setAdminNote]: $TSFixMe = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add isLoading state
  const [isBtnLoading, setIsBtnLoading] = useState(false); // Add isLoading state
  const [videoTime, setVideoTime] = useState(0); // Add isLoading state
  const [speedTimeStamp, setSpeedTimeStamp] = useState(0); // Add isLoading state
  const [videoTotalTime, setVideoTotalTime] = useState(0);
  const [selectedSpeedLocationData, setSelectedSpeedLocationData]: $TSFixMe = useState({}); // Add isLoading state
  const videos = useRef<HTMLVideoElement[]>([]);
  const { encryptedParams }: $TSFixMe = useParams();
  const decryptData = decrypt(encryptedParams);

  const togglePlayAll = () => {
    setIsPlaying(!isPlaying);
  };

  const downloadVideos = async () => {
    try {
      const zip = new JSZip();
      const videoUrls = [videoInfo?.c1?.path, videoInfo?.c2?.path, videoInfo?.c3?.path, videoInfo?.c4?.path, videoInfo?.c5?.path]; // Add all video URLs here

      const promises = videoUrls.map(async (url, index) => {
        if (url) {
          const response = await axios.get(url, { responseType: 'blob' });
          zip.file(`video_${index}.mp4`, response.data);
        }
      });

      await Promise.all(promises);

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = 'videos.zip';
      link.click();
    } catch (error) {
      console.error('Error downloading videos:', error);
    }
  };

  const getVideoInfo = async () => {
    try {
      setGyroLoading(true); // Set gyroLoading to true before starting the fetch
      if (decryptData.id && decryptData.deviceId && decryptData.projectId && decryptData.type) {
        const data = {
          deviceId: decryptData.deviceId,
          id: decryptData.id,
          projectId: decryptData.projectId,
          timestamp: decryptData.timestamp,
          startTimestamp: decryptData.startTimestamp,
          endTimestamp: decryptData.endTimestamp,
        }
        const [res] = await Promise.all([
          getEventVideoInformation(data, decryptData.type),
        ]);
        setVideoInfo(res?.result);

        if ((decryptData.startTimestamp && decryptData.endTimestamp) || decryptData.isIgnore) {
          const [gyroRes, speedLocation] = await Promise.all([
            getEventVideoGyroInformation(data, decryptData.type),
            getEventVideoSpeedLocation(data, decryptData.type)
          ]);
          setGyroInfo(gyroRes?.result);
          setVideoSpeedLocation(speedLocation?.result);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGyroLoading(false); // Set gyroLoading to false after the fetch is complete
    }
  }

  const fetchAdminNote = async (pageNo?: number) => {
    setIsLoading(true);
    try {
      const data = {
        no: pageNo ? pageNo : page, // Adjust how you get the page number here
        size: 10,
      };
      const res = await getAdminNote(data, decryptData.type, decryptData.id);
      if (res.status === 200) {
        const notesData = res.result.data;
        if (notesData.length !== 0) {
          setAdminNote((prev: $TSFixMe) => {
            // Create a set of existing Notes IDs for quick lookup
            const existingNoteIds = new Set(
              prev.map((note: $TSFixMe) => note.id)
            );

            // Filter out new Notes with existing IDs
            const uniqueNewNotesData = notesData.filter(
              (note: $TSFixMe) =>
                !existingNoteIds.has(note.id)
            );

            // Concatenate the unique new Notes with the existing Notes
            const mergedNotes = [...prev, ...uniqueNewNotesData];
            return mergedNotes;
          });
        }

        // Check if there are more pages to load
        if (notesData.length === 0) {
          setHasMore(false);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmitNote = async () => {
    try {
      if (inputText) {
        setIsBtnLoading(true);
        const data = {
          deviceId: decryptData.deviceId,
          note: inputText,
          projectId: decryptData.projectId,
          refId: decryptData.id,
        }
        const res = await addAdminNote(data, decryptData.type);
        if (res.status === 200) {
          setAdminNote((prev: $TSFixMe) => {
            const mergedNotes = [res.result, ...prev];
            return mergedNotes;
          });
          setInputText("");
          setIsBtnLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setIsBtnLoading(false);
    }
  }

  // Function to render each Notes item
  const renderNotes = (notes: $TSFixMe, index: number) => (
    <div className="notes-box" key={index}>
      <div className="user-note">
        <p className="notes-detail">{notes.email}</p>
        <p className="notes-detail note">{notes.note}</p>
        {notes.createdOn &&
          <p className="notes-detail">{convertTimestampToDate(props.user?.timeZone?.zoneId, notes.createdOn, null, props.user.timeFormat)}</p>}
      </div>
    </div>
  );

  const handleLoadedMetadata = () => {
    setVideoTotalTime(videos.current[0].duration);
  };

  useEffect(() => {
    if (isPlaying) {
      videos.current.forEach((video) => video.play());
    } else {
      videos.current.forEach((video) => video.pause());
    }
  }, [isPlaying]);

  useEffect(() => {
    if (!isLoading && props.user.id !== 0) {
      fetchAdminNote();
    }
  }, [page, props.user.id]);

  useEffect(() => {
    const handleTimeUpdate = () => {
      const videoElement = videos.current[0];
      const totalDuration = videos?.current[0]?.duration;
      // Use the provided starting timestamp and add the elapsed time from the video's currentTime
      const currentTimestamp = videoInfo?.startTimestamp + Math.floor(videoElement.currentTime);
      if (totalDuration === videoElement.currentTime) {
        setSpeedTimeStamp(videoInfo?.endTimestamp);
      } else {
        setSpeedTimeStamp(currentTimestamp);
      }
      setVideoTime(videoElement.currentTime);
    };

    // Add event listener for time updates
    videos.current[0].addEventListener('timeupdate', handleTimeUpdate);

    // Clean up the event listener on component unmount
    return () => {
      videos.current[0].removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [videos.current[0], videoInfo]);

  useEffect(() => {
    if (speedTimeStamp && videoSpeedLocation && Object.keys(videoSpeedLocation).length > 0) {
      const tempData = videoSpeedLocation.find((v: $TSFixMe) => v.timestamp === speedTimeStamp);
      setSelectedSpeedLocationData(tempData);
    } else {
      setSelectedSpeedLocationData(videoSpeedLocation ? videoSpeedLocation[0] : {});
    }
    return () => { }
  }, [speedTimeStamp, videoSpeedLocation])

  useEffect(() => {
    getVideoInfo();
  }, []);


  return (
    <div className={`header bg-gradient-info pb-2 pt-0 pt-md-8 allPageTab ${selectedTheme}`}>
      <FullPageLoaderModal isLoading={props.isLoading} />
      <Container className="mt--0 px-1" fluid>
        <div className="card-stats md-4 mb-xl-0 pb-1">
          <Row className="mx-0">
            {/* <Col sm={12} md={6} className="videHeader tripsname tabAll">
              <span className="buttonLink label-light cursor-pointer" onClick={togglePlayAll}>
                Having trouble in playing Video ?
              </span>
            </Col> */}
            <Col sm={12} md={12} className="videHeader pageEnd tabAll tabSet">
              <Button onClick={downloadVideos} className={`${selectedTheme}`}>
                Download Data
              </Button>
            </Col>
          </Row>
        </div>
        <div className="card-stats md-0 pb-0 mb-xl-0 videomiddle devi">
          <Row className="align-items-start mx-0">
            <Col className="col-lg-6 col-12 tabAll">
              <div className="vidthumbfirst vidthumbfirstdiv">
                <div className={`overlay`}>
                  <div className={`overlayText d-flex align-items-center ${selectedSpeedLocationData && Object.keys(selectedSpeedLocationData).length > 0 ? "justify-content-between" : "justify-content-center"}`}>
                    {selectedSpeedLocationData?.timestamp && <div className="top-info">{convertTimestampToDate(props.user?.timeZone?.zoneId, selectedSpeedLocationData.timestamp, null, props.user.timeFormat)}</div>}
                    <div className="top-info">{videoInfo?.camera1_name ?? "Main Channel"}</div>
                    {Number(selectedSpeedLocationData?.speed) !== null && <div className="top-info">{selectedSpeedLocationData?.speed ?? "-"}km/h</div>}
                  </div>
                  <video
                    controls
                    className="vid vid-main"
                    onLoadedMetadata={handleLoadedMetadata}
                    ref={(el) => (videos.current[0] = el as HTMLVideoElement)}
                    onError={(e: $TSFixMe) => {
                      console.error("Video error:", e);

                      // Additional logging for more detailed information
                      console.error("Error type:", e.type);
                      console.error("Error message:", e.message);
                    }}
                    onPlay={togglePlayAll}
                  >
                    {videoInfo && videoInfo?.c1?.path ? (
                      <source src={videoInfo?.c1?.path} type="video/mp4" />
                    ) : (
                      <div className="video-not-available">
                        Video not available
                      </div>
                    )}
                  </video>
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-12 tabAll">
              <div className={"thumb1 channel1 thumb1div"}>
                <div className={`overlay`}>
                  <div className="overlayText">
                    <h2 className="mt-1">{videoInfo?.camera2_name ?? "Channel 1"}</h2>
                  </div>
                  {videoInfo && videoInfo?.c2?.path ? (
                    <video
                      controls
                      className="vid vid-grid"
                      ref={(el) =>
                        (videos.current[1] = el as HTMLVideoElement)
                      }
                    >
                      <source src={videoInfo?.c2?.path} type="video/mp4" />
                    </video>
                  ) : (
                    <div className=" vid vid-grid video-not-available">
                      <div>
                        <PlayDisabledIcon style={{ fill: "black" }} fontSize="large" />
                      </div>
                      <>Video not available</>
                    </div>
                  )}

                </div>
              </div>
              <div className={"thumb2 channel2 thumb2div"}>
                <div className={`overlay`}>
                  <div className="overlayText">
                    <h2 className="mt-1">{videoInfo?.camera3_name ?? "Channel 2"}</h2>
                  </div>
                  {videoInfo && videoInfo?.c3?.path ? (
                    <video
                      controls
                      className="vid vid-grid"
                      ref={(el) =>
                        (videos.current[2] = el as HTMLVideoElement)
                      }
                    >
                      <source src={videoInfo?.c3?.path} type="video/mp4" />
                    </video>
                  ) : (
                    <div className=" vid vid-grid video-not-available">
                      <div>
                        <PlayDisabledIcon style={{ fill: "black" }} fontSize="large" />
                      </div>
                      <>Video not available</>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-12 tabAll">
              <div className={"thumb1 channel3 thumb1Channel3"}>
                <div className={`overlay`}>
                  <div className="overlayText">
                    <h2 className="mt-1">{videoInfo?.camera4_name ?? "Channel 3"}</h2>
                  </div>
                  {videoInfo && videoInfo?.c4?.path ? (
                    <video
                      controls
                      className="vid vid-grid"
                      ref={(el) =>
                        (videos.current[3] = el as HTMLVideoElement)
                      }
                    >
                      <source src={videoInfo?.c4?.path} type="video/mp4" />
                    </video>
                  ) : (
                    <div className=" vid vid-grid video-not-available">
                      <div>
                        <PlayDisabledIcon style={{ fill: "black" }} color="primary" fontSize="large" />
                      </div>
                      <>Video not available</>
                    </div>
                  )}
                </div>
              </div>
              <div className={"thumb2 channel4 thumb2div1"}>
                <div className={`overlay`}>
                  <div className="overlayText">
                    <h2 className="mt-1">{videoInfo?.camera5_name ?? "Channel 4"}</h2>
                  </div>
                  {videoInfo && videoInfo?.c5?.path ? (
                    <video
                      controls
                      className="vid vid-grid"
                      ref={(el) =>
                        (videos.current[4] = el as HTMLVideoElement)
                      }
                    >
                      <source src={videoInfo?.c5?.path} type="video/mp4" />
                    </video>
                  ) : (
                    <div className=" vid vid-grid video-not-available">
                      <div>
                        <PlayDisabledIcon style={{ fill: "black" }} color="primary" fontSize="large" />
                      </div>
                      <>Video not available</>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-stats md-4 mb-xl-0 videofoot devi mt-2">
          <Row className="mx-0">
            <Col className="col-sm-12 tabAll" md={3}>
              <div className="boxMap">
                <Loc markerData={selectedSpeedLocationData} deviceId={decryptData.deviceId} user={props.user} />
              </div>
            </Col>
            <Col col-sm-12 md={6} className="col-sm-12 d-flex justify-content-center highEventFoot tabAll">
              <div className="graph-colDiv2 videoGraphDiv">
                {gyroLoading ? (
                  <div className="d-flex justify-content-center">
                    <Spinner color="primary" size="lg" />
                  </div>
                ) : (
                  <div className="videoGraphDiv">
                    <VideoGraph selectedTheme={selectedTheme} videoTotalTime={videoTotalTime} gyroInfo={gyroInfo} videoTime={videoTime} gyroLoading={gyroLoading} />
                  </div>
                )}
              </div>
            </Col>
            <Col className={`col-sm-12 tabAll ${selectedTheme}`} md={3}>
              <div className="boxNotes">
                <h2 className="panelHeading">Notes</h2>
                {adminNote.length === 0 &&
                  <div className="text-center notes-section no-data">No Data Available</div>
                }
                {adminNote.length > 0 &&
                  <div
                    className="notes-section"
                    id="noteScrollableDiv"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  >
                    <InfiniteScroll
                      dataLength={adminNote.length}
                      style={{ display: 'flex', flexDirection: 'column-reverse' }}
                      next={() => setPage(page + 1)} // Load more data when scrolling
                      hasMore={hasMore && !isLoading} // Prevent loading when an API call is in progress
                      inverse={true}
                      loader={
                        isLoading && (
                          <Spinner
                            color="primary"
                            size="sm"
                            className="mx-auto d-block"
                          />
                        )
                      } // Loading indicator
                      scrollableTarget="noteScrollableDiv"
                      className="overflow-hidden"
                    >
                      {adminNote.length > 0 && adminNote.map((notes: $TSFixMe, index: number) =>
                        renderNotes(notes, index)
                      )}
                    </InfiniteScroll>
                  </div>
                }
                <div className="notes-form">
                  <div className="chatInputBar">
                    <input
                      autoComplete="disable"
                      type="search"
                      id="noteID"
                      className="chatInput"
                      placeholder="Enter Your Note"
                      onChange={(e) => setInputText(e.target.value)}
                      value={inputText}
                    />
                  </div>
                  <div>
                    <Button
                      type="button"
                      id="noteSave"
                      disabled={isBtnLoading}
                      className={`${selectedTheme} h-100 m-0 ms-2 w-px-65 d-flex align-items-center justify-content-center`}
                      onClick={() => handleSubmitNote()}
                    >
                      {isBtnLoading ?
                        <Spinner color="light"
                          size="sm" />
                        : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container >
    </div >
  );
}

const mapStateToProps = (state: RootState) => ({
  chatData: state.dataStore.chatData,
  isLoading: state.generalSlice.isLoading,
  user: state.userSlice.user,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addChatdata: (msg: $TSFixMe) => dispatch(addChatdata(msg)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HighEventsVideo);
